@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
@import "https://unpkg.com/open-props";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
  font-size: 15px;
  scroll-behavior: smooth;
  color: #ffffff;
  /* scrollbar-color: #8311b3 #2f0640; */
}

.purple-scrollbar {
  --primary: #8311b3;
  --secondary: #2f0640;
  scrollbar-width: thin;
  scrollbar-color: var(--primary) var(--secondary);
}

.truncate1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

::selection {
  background: #ff8a00;
  color: #ffffff;
}

/* .backgroundImage {
  background-image: url();
} */

.kaushan-script {
  font-family: "Kaushan Script", cursive;
  font-weight: 400;
  font-style: normal;
}

.text-stroke-purple {
  background: linear-gradient(to bottom, #f7e3ff, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 4px transparent;
  color: white;
  text-wrap: nowrap;
}

.text-gradient-gray {
  background: linear-gradient(to bottom, #f2f2f2, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.vertical-text {
  writing-mode: vertical-rl;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  scroll-behavior: smooth;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-radio-input {
  all: unset;
  width: 16px;
  height: 16px;
  accent-color: #ff8a00;
  border-radius: 999px;
  outline: 1px solid grey;
  outline-offset: 3px;
  box-sizing: border-box;
}
.custom-radio-input:checked {
  background-color: #ff8a00;
  outline-color: black;
}

input:-internal-autofill-selected {
  background-color: #eeeeee !important;
}

.semi-circle {
  --mask-gradient: linear-gradient(
    to bottom,
    transparent,
    white 0%,
    transparent 50% 100%
  );

  -webkit-mask-image: var(--mask-gradient);
  mask-image: var(--mask-gradient);
}

.icon-ball {
  --reflect-bottom: 20.25rem;
  --light1-x: 30%;
  --light1-y: 30%;
  --light2-x: 50%;
  --light2-y: 0%;

  /* position: absolute; */
  scale: 0.7;
  inset: 0;
  display: grid;
  place-content: center;

  width: 5rem;
  aspect-ratio: 1;
  /* margin: auto auto auto 0rem; */
  overflow: hidden;
  perspective: 1rem;

  background: #8311b3;
  border-radius: 50%;

  background-image: radial-gradient(
      circle at var(--light2-x) calc(100% - var(--light2-y)),
      #2f0640 0% 0.5%,
      #0000,
      #0000 90% 100%
    ),
    radial-gradient(
      circle at var(--light1-x) var(--light1-y),
      #8311b3,
      #0000 15%
    ),
    radial-gradient(
      circle at var(--light2-x) var(--light2-y),
      #2f0640 0% 10%,
      #0000,
      #0002 90% 100%
    ),
    radial-gradient(
      circle at var(--light1-x) var(--light1-y),
      #8311b3 10%,
      #000e 90% 100%
    );

  translate: -1.5rem 5rem;
  transition: all var(--ball-trans-duration) var(--ease-spring-5) 1.5s,
    --reflect-bottom var(--ball-trans-duration) var(--ease-spring-5) 1.5s,
    --light1-x var(--ball-trans-duration) var(--ease-spring-5) 0.5s,
    --light1-y var(--ball-trans-duration) var(--ease-spring-5) 0.5s,
    --light2-x var(--ball-trans-duration) var(--ease-spring-5) 0.5s,
    --light2-y var(--ball-trans-duration) var(--ease-spring-5) 0.5s;

  /* -webkit-box-reflect: below var(--reflect-bottom)
    linear-gradient(transparent 50%, #fff9); */

  @starting-style {
    --reflect-bottom: 181svh;
    --light1-x: 25%;
    --light1-y: 95%;
    --light2-x: 35%;
    --light2-y: 100%;

    translate: -1.5rem -100svh;
  }

  animation: bounce 1s;
  transform: translateY(0px);
  animation-iteration-count: infinite;
}

.icon-ball img {
  width: 4rem;
  border-radius: 50%;

  filter: grayscale(1) contrast(2);
  mix-blend-mode: lighten;

  rotate: y -3deg;
  translate: -0.8rem 0px;
  animation: rotate-icon 2s ease-in-out infinite;
}

@property --light1-x {
  syntax: "<length-percentage>";
  inherits: true;
  initial-value: 0%;
}

@property --light1-y {
  syntax: "<length-percentage>";
  inherits: true;
  initial-value: 0%;
}

@property --light2-x {
  syntax: "<length-percentage>";
  inherits: true;
  initial-value: 0%;
}

@property --light2-y {
  syntax: "<length-percentage>";
  inherits: true;
  initial-value: 0%;
}

@keyframes rotate-icon {
  50% {
    rotate: y 0deg;
    translate: 0rem 0px;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-100%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/* @keyframes bounce {
  0% {
    top: 0;
    -webkit-animation-timing-function: ease-in;
  }
  40% {
  }
  50% {
    top: 140px;
    height: 140px;
    -webkit-animation-timing-function: ease-out;
  }
  55% {
    top: 160px;
    height: 120px;
    -webkit-animation-timing-function: ease-in;
  }
  65% {
    top: 120px;
    height: 140px;
    -webkit-animation-timing-function: ease-out;
  }
  95% {
    top: 0;
    -webkit-animation-timing-function: ease-in;
  }
  100% {
    top: 0;
    -webkit-animation-timing-function: ease-in;
  }
} */

.react-international-phone-input-container {
  height: 50px;
  border-radius: 6px;
  & > * {
    background: #f2f2f2;
  }
}

.react-international-phone-country-selector {
  & > button {
    height: 50px;
  }
}

.react-international-phone-input,
.react-international-phone-country-selector-button {
  background-color: #f2f2f2 !important;
  border-radius: 6px;
  &:focus {
    outline: 1px solid #8311b3;
  }
}
